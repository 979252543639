// based on tree-shaking init described here:
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/tree-shaking/
// goal is to minimize bundle size

import {
  BrowserClient,
  breadcrumbsIntegration,
  dedupeIntegration,
  defaultStackParser,
  getCurrentScope,
  globalHandlersIntegration,
  httpContextIntegration,
  httpClientIntegration,
  makeFetchTransport,
  linkedErrorsIntegration,
} from '@sentry/browser';

let client: BrowserClient

function initSentry() {
  client = new BrowserClient({
    dsn: 'https://1b3a6d9a8f75464d9add33b64eb9098a@o4504499082625024.ingest.sentry.io/4504499084853248',
    environment: import.meta.env.DEV ? 'dev' : 'production',
    release: (import.meta.env.VITE_SENTRY_RELEASE as string || 'default'),

    tunnel: '/api/health/report',

    transport: makeFetchTransport,
    stackParser: defaultStackParser,

    // Only the integrations listed here will be used
    integrations: [
      breadcrumbsIntegration(),
      globalHandlersIntegration(),
      linkedErrorsIntegration(),
      dedupeIntegration(),
      httpContextIntegration(),
      httpClientIntegration(),
    ],
  })

  getCurrentScope().setClient(client);
  client.init()
}

export {
  initSentry,
  client,
}
